<template>
  <div
    ref="test"
    class="test"
  >
    <Header
      :login-form-is-hidden="true"
      class="test__header"
      :testTitle="test"
      :name="formData"
      :button-is-hidden="false"
      :timer-is-hidden="this.test?.testQuestion?.series !== 2"
      :s-count="this.formattedCountdown"
      :on-renouncement="onRenouncement"
      :question-number="this.questionNumber"
      :questions-per-series="this.questionsPerSeries"
    />

    <template v-if="!this.testIsOver">
      <div class="test-finish">
        <h1 class="test-finish__title">
          <!--Тест окончен. <br />
          Спасибо за участие-->
          {{ $t('test.test-finish__title-FIRST') }} <br />
          {{ $t('test.test-finish__title-SECOND') }}
        </h1>
        <p
          class="test-finish__exit"
          @click="finishTest()"
        ><!--Выйти-->{{ $t('test.test-finish__exit') }}</p>
      </div>
    </template>
    <template v-if="questionTemplate && test.message == null && this.testIsOver">
      <div>
        <div class="test-sound">
          <h1 class="test-sound__title">
            <!--Задание--> {{ $t('test.test-sound__title') }}
            <span>
              {{ this.questionNumber }}
            </span>
          </h1>
          <h2 class="test-sound__title-sound"><!--Прослушать--> {{ $t('test.test-sound__title-sound') }}</h2>
          <div>
            <!-- @click="question" -->
            <div v-if="test.testQuestion && test.testQuestion.soundUrl">
              <template v-if="svgVisibilityNew">
                <button
                  id="questionAuto"
                  :disabled="this.isPlaying"
                >
                  <Audio class="test-sound__audio-svg" />
                </button>
              </template>
              <template v-if="gifVisibilityNew">
                <img
                  class="test-sound__gif"
                  src="../../assets/images/sound.gif"
                  alt="gif"
                />
              </template>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-if="answerTemplate && test.message == null && this.testIsOver">
      <form @submit.prevent="handleSubmit">
        <div
          class="test__section"
          @mousemove="handleMouseMove"
        >
          <h1
            class="test__title"
            @mousemove="handleMouseMove"
          ><!--Ваш ответ--> {{ $t('test.test__title') }}</h1>
          <div class="test__container">
            <div class="test__wrapper-group">
              <div class="test__group">
                <h4 class="test__title-group"><!--Мой позывной--> {{ $t('test.test__title-group-ME') }}</h4>
                <div
                  class="test__checkbox-group"
                  v-for="callsinNames in test"
                  :key="callsinNames.id"
                >
                  <div
                    v-for="(item, index) in callsinNames.yourCallsignNames"
                    :key="item.id"
                  >
                    <input
                      type="radio"
                      name="testRadio"
                      class="custom-checkbox"
                      :id="index"
                      :value="item"
                      :disabled="isNamesCallsignDisabled"
                      v-model="checkedNamesCallsign"
                      @click="handleFirstClick"
                    />
                    <label :for="index">{{ item }}</label>
                  </div>
                </div>
              </div>
              <div class="test__group">
                <h4 class="test__title-group"><!--Позывной друга-->{{ $t('test.test__title-group-FRIEND') }}</h4>
                <div
                  class="test__checkbox-group"
                  v-for="alienCallsignNames in test"
                  :key="alienCallsignNames.id"
                >
                  <div
                    v-for="(
                      item, index
                    ) in alienCallsignNames.alienCallsignNames"
                    :key="item.id"
                  >
                    <input
                      type="radio"
                      name="testRadioNext"
                      class="custom-checkbox"
                      :id="index + 100"
                      :value="item"
                      :disabled="isNamesFriendDisabled"
                      v-model="checkedNamesFriend"
                      @click="handleFirstClick"
                    />
                    <label :for="index + 100">{{ item }}</label>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <h3 class="test__title-img-group">
                <!--Укажите мышкой в матрице запрошенные фигуры в установленном
                порядке-->
                {{ $t('test.test__title-img-group') }}
              </h3>
              <div
                class="test__img-group"
                v-for="figures in test"
                :key="figures.id"
              >
                <div
                  v-for="(item, index) in figures.figures"
                  :key="item.id"
                >
                  <input
                    type="checkbox"
                    class="checkbox-img"
                    :id="index + 1000"
                    :value="item.code"
                    v-model="checkedNamesFigures"
                    :disabled="isNamesFiguresDisabled"
                    @click="handleFirstClick"
                  />
                  <label :for="index + 1000">
                    <img
                      :src="
                        'https://api.psylab.test.ut.in.ua/storage/figures/images/' +
                        item.code +
                        '.png'
                      "
                      alt="question"
                      class="test__img"
                    />
                  </label>
                </div>
              </div>
            </div>
          </div>
          <ButtonMain
            :type="'submit'"
            class="test__button"
            id="addButton"
            @click.native="myReload()"
          >
            <!--Подтвердить ответ-->
            {{ $t('test.test__button-SELECT') }}
          </ButtonMain>
        </div>
      </form>
    </template>
  </div>
</template>

<script>
import {
  getStart,
  nextQuestion,
  renouncement,
  getRespondentInfo,
} from "../../api/supportApi";
import ButtonMain from "../../atoms/ButtonMain.vue";
import Header from "../Header.vue";
import Audio from "../../assets/images/audio/audio.svg";

const asyncTimeout = (ms) => new Promise((res) => setTimeout(() => res(), ms));

export default {
  name: "Test",
  data() {
    return {
      inputs: [],
      userInfo: {},
      test: {},
      formData: {},
      answerTemplate: false,
      finishTemplate: false,
      questionTemplate: true,
      sound: {},
      timeWholeAnswer: "",
      checkedNamesCallsign: [],
      checkedNamesCallsignLength: null,
      checkedNamesFriend: [],
      checkedNamesFigures: [],
      checkedNamesFriendLength: null,
      checkedNames: [],
      total: "",
      finish: {},
      gifVisibilityNew: false,
      svgVisibilityNew: true,
      testOrder: "",
      thisTimeSpeed: 1000,
      firstClickTime: null,
      lastClickTime: null,
      timeBeforeInteraction: null,
      timeSinceLastClick: null,
      isPlaying: false,
      status: 2,
      isNamesCallsignDisabled: false,
      isNamesFriendDisabled: false,
      isNamesFiguresDisabled: false,
      countdown: 35,
      timer: null,
      countdownTimer: null,
      timeAnswer1: null,
      timeAnswer2: null,
      timeAnswer3: null,
      timeAnswer4: null,
      timeAnswer5: null,
      formattedCountdown: 35,
      testIsOver: true,
      audio: null,
      timeBeforeInteractionNew: null,
      finishTestNew: true,
      questionNumber: 1,
      finishSoundNew: true,
      startTime: null,
      questionsPerSeries: null
    };
  },
  components: {
    ButtonMain,
    Header,
    Audio,
  },
  created() {
    window.addEventListener('load', this.redirectToHome);
    window.addEventListener('beforeunload', this.handleBeforeUnload);
  },
  beforeDestroy() {
    window.removeEventListener('load', this.redirectToHome);
    window.removeEventListener('beforeunload', this.handleBeforeUnload);
  },
  mounted() {
  if (window.history && window.history.pushState) {
    window.history.pushState('test', null, './test');
    window.history.forward();
    setTimeout(function() {
      window.history.back();
    }, 500);
  }
  this.disableBackButton();
  window.addEventListener('unload', this.handleUnload)
},
  watch: {
    actualAccount: {
      immediate: true,
      handler() {
        getStart()
          .then((res) => {
            this.test = res.data;
            this.questionsPerSeries = this.test.test.questionsPerSeries
            this.finish = this.test.message;
            this.myReload();
          })
          .catch((error) => {
            const { data } = error.response;
            this.error = data;
            if (this.error.nonFieldError) {
              window.location.href = '/'
            }
            if(this.error?.nonFieldError[0] == 'Токен просрочен.') {
              window.location.href = '/'
            }
          });
        getRespondentInfo()
          .then((res) => {
            this.formData = res.data;
          })
          .catch((error) => {
            const { data } = error.response;
            this.error = data;
            if (this.error.nonFieldError) {
              window.location.href = '/'
            }
            if(this.error.nonFieldError[0] =='Тест закрыт для прохождения..') {
              window.location.href = '/'
            }
          });
      },
    },
    answerTemplate: {
      handler() {
        if (this.answerTemplate == true && this.test.testQuestion.series === 2) {
          this.timer = setTimeout(() => {
            if (this.finishTestNew) {
              this.handleSubmit()
            }
          this.myReload()
        }, 35000)

        // Запускаем таймер
        this.countdownTimer = setInterval(() => {
          if (this.countdown > 0) {
            this.countdown--
            this.formattedCountdown = this.countdown.toString().padStart(2, '0')
          }
        }, 1000)
      }
    }
    },
    checkedNamesCallsign: function(newVal) {
      this.checkedNames.push(newVal);
      this.isNamesCallsignDisabled = true
    },
    checkedNamesFriend: function(newVal) {
      this.checkedNames.push(newVal);
      this.isNamesFriendDisabled = true
    },
    checkedNamesFigures: function(newVal) {
      for (let i = 0; i < this.checkedNamesFigures.length; i++ ) {
        if (!this.checkedNames.includes(newVal[i])) {
          this.checkedNames.push(newVal[i])
        }
      }
      if (this.test.testQuestion.series === 2) {
        if (this.checkedNamesFigures.length > 2) {
          this.isNamesFiguresDisabled = true
        }
      } else {
        if (this.checkedNamesFigures.length > 1) {
          this.isNamesFiguresDisabled = true
        }
      }
    },
  },
  methods: {
    handleBeforeUnload(event) {
      this.onRenouncement()
      
    },
    handleMouseMove(event) {
      if (!this.startTime) {
        this.startTime = Date.now() - this.firstClickTime;
      }
    },
    handleCheckboxChange(event, code) {
      if (this.checkedNamesFigures.includes(code)) {
        event.preventDefault()
        return
      }
    },
    redirectToHome() {
      this.onRenouncement()
    },
    handleUnload() {
      this.$router.push('https://psylab.test.ut.in.ua/')
    },
    handleFirstClick() {
      if (this.timeBeforeInteraction === null && this.timeAnswer1 === null) {
        this.timeAnswer1 = Date.now() - this.firstClickTime;
      } else if (this.timeBeforeInteraction !== null && this.timeAnswer1 !== null && this.timeAnswer2 === null) {
        this.timeAnswer2 = Date.now() - this.firstClickTime;
        this.timeAnswer2 = this.timeAnswer2 - this.timeAnswer1
      } else if (this.timeBeforeInteraction !== null && this.timeAnswer1 !== null && this.timeAnswer2 !== null && this.timeAnswer3 === null) {
        this.timeAnswer3 = Date.now() - this.firstClickTime;
        this.timeAnswer3 = this.timeAnswer3 - this.timeAnswer2
      } else if (this.timeBeforeInteraction !== null && this.timeAnswer1 !== null && this.timeAnswer2 !== null && this.timeAnswer3 !== null && this.timeAnswer4 === null) {
        this.timeAnswer4 = Date.now() - this.firstClickTime;
        this.timeAnswer4 = this.timeAnswer4 - this.timeAnswer3
      } else if (this.timeBeforeInteraction !== null && this.timeAnswer1 !== null && this.timeAnswer2 !== null && this.timeAnswer3 !== null && this.timeAnswer4 !== null && this.timeAnswer5 === null) {
        this.timeAnswer5 = Date.now() - this.firstClickTime;
        this.timeAnswer5 = this.timeAnswer5 - this.timeAnswer4
      }
      if (this.timeBeforeInteractionNew == null) {
        this.timeBeforeInteractionNew = this.timeBeforeInteraction
      }
      this.timeBeforeInteraction = Date.now() - this.firstClickTime;
    },
    handleSubmit() {
      if (this.finishTestNew) {
      this.lastClickTime = Date.now()
      this.timeSinceLastClick = this.lastClickTime - this.firstClickTime;
      this.timeWholeAnswer = this.timeSinceLastClick;
      const { userInfo, test } = this;
      const { testQuestionId } = test.testQuestion;
      if (this.timeBeforeInteractionNew == null) {
        this.timeBeforeInteractionNew = Date.now() - this.firstClickTime;
      }
      if (this.startTime === null) {
        this.startTime = this.timeWholeAnswer
      }
        nextQuestion({
          userInfo,
          test_question_id: testQuestionId,
          answer1: this.checkedNames[0] || "0",
          answer2: this.checkedNames[1] || "0",
          answer3: this.checkedNames[2] || "0",
          answer4: this.checkedNames[3] || "0",
          answer5: this.checkedNames[4] || "0",
          time_before_interaction: this.startTime,
          time_whole_answer: this.timeWholeAnswer,
          time_answer1: this.timeAnswer1,
          time_answer2: this.timeAnswer2,
          time_answer3: this.timeAnswer3,
          time_answer4: this.timeAnswer4,
          time_answer5: this.timeAnswer5
        })
          .then((res) => {
            this.test = res.data;
            this.status = res
            this.questionNumber = (this.questionsPerSeries * (this.test?.testQuestion?.series - 1) + this.test?.testQuestion?.order)
            // this.questionNumber = this.test?.testQuestion?.order
            if (this.test?.testQuestion?.series === 3 && this.test?.testQuestion?.order === this.questionsPerSeries) {
              this.finishTestNew = false
            }
            if(this.status === 1) {
              this.$router.push("/");
            }
            this.testOrder = this.test.testQuestion.order;
            this.firstClickTime = null
            this.lastClickTime = null
            this.isNamesFriendDisabled = false
            this.isNamesCallsignDisabled = false
            this.isNamesFiguresDisabled = false
            this.checkedNames = []
            this.countdown = 35
            this.formattedCountdown = 35
            clearTimeout(this.timer);
            clearTimeout(this.countdownTimer);
            this.isPlaying = true
            this.timeBeforeInteraction = null
            this.timeWholeAnswer = null
            this.timeAnswer1 = null
            this.timeAnswer2 = null
            this.timeAnswer3 = null
            this.timeAnswer4 = null
            this.timeAnswer5 = null
            this.timeBeforeInteractionNew = null
            this.startTime = null
          })
          .catch((error) => {
            const { data } = error.response;
            this.error = data; 
            if(this.error.nonFieldError[0]) {
              this.testIsOver = false
              this.audio.pause()
            }
            this.isNamesFriendDisabled = false
            this.isNamesCallsignDisabled = false
            this.isNamesFiguresDisabled = false
            this.checkedNames = []
            this.countdown = 35
            this.formattedCountdown = 35
            clearTimeout(this.timer);
            clearTimeout(this.countdownTimer);
            if(this.error.nonFieldError[0] == 'Токен просрочен.' || this.error.nonFieldError[0] == 'Тест закрыт для прохождения..') {
              this.$router.push("/");
              this.audio.pause( )
            }
          });
      this.questionTemplate = true;
      this.answerTemplate = false;
      this.gifVisibilityNew = false;
      this.svgVisibilityNew = true;
      this.checkedNames = [];
      this.checkedNamesCallsign = [];
      this.checkedNamesFriend = [];
      this.checkedNamesFigures = [];
      this.checkedNamesCallsignLength = 0;
      this.checkedNamesFriendLength = 0
      this.isNamesFriendDisabled = false
      this.isNamesCallsignDisabled = false
      this.isNamesFiguresDisabled = false
      this.countdown = 35
      this.formattedCountdown = 35
      clearTimeout(this.timer);
      clearTimeout(this.countdownTimer);
      if (this.finish === "Тест закончен.") {
        this.$router.push("/");
      }
    }else {
      this.finishSoundNew = false
          this.testIsOver = false
          this.audio.pause()
          const { userInfo, test } = this;
          const { testQuestionId } = test.testQuestion;
          this.lastClickTime = Date.now()
          this.timeSinceLastClick = this.lastClickTime - this.firstClickTime;
          this.timeWholeAnswer = this.timeSinceLastClick;
          if (this.timeBeforeInteractionNew == null) {
            this.timeBeforeInteractionNew = Date.now() - this.firstClickTime;
          }
          nextQuestion({
          userInfo,
          test_question_id: testQuestionId,
          answer1: this.checkedNames[0] || "0",
          answer2: this.checkedNames[1] || "0",
          answer3: this.checkedNames[2] || "0",
          answer4: this.checkedNames[3] || "0",
          answer5: this.checkedNames[4] || "0",
          time_before_interaction: this.startTime,
          time_whole_answer: this.timeWholeAnswer,
          time_answer1: this.timeAnswer1,
          time_answer2: this.timeAnswer2,
          time_answer3: this.timeAnswer3,
          time_answer4: this.timeAnswer4,
          time_answer5: this.timeAnswer5
        })
          }
    },
    async question() {
      const baseUrl = "https://api.psylab.test.ut.in.ua/storage/tests/sounds/";
      this.audio = new window.Audio(
        `${baseUrl}${this.test.testQuestion.soundUrl}`
      );

      const onEnd = () => {
        this.answerTemplate = true;
        this.firstClickTime = Date.now()
        this.questionTemplate = false;
      };

      this.audio.onended = onEnd;

      try {
        if (this.finishSoundNew) {
          await this.audio.play();
        }
        this.gifVisibilityNew = true;
        this.svgVisibilityNew = false;
      } catch (e) {
        this.gifVisibilityNew = false;
        this.svgVisibilityNew = true;
        if (
          e.message.includes(
            "failed because the user didn't interact with the document first"
          )
        ) {
        } else {
          if (this.finishTestNew) {
            await this.audio.play();
          }
        }
      }
    },
    onRenouncement() {
      if (this.finishTestNew) {
        this.handleSubmit();
      }
      renouncement();
      this.$router.push("/");
      this.$router.push('https://psylab.test.ut.in.ua/')
    },
    async myReload() {
      await asyncTimeout(1000);
      await this.question();
    },
    finishTest() {
      this.$router.push("/");
    },
    disableBackButton() {
    history.pushState(null, null, location.href);
    window.onpopstate = function(event) {
      history.go(1);
    }
  },
  },
};
</script>

<style lang="scss" scoped>
.test {
  min-height: calc(100vh);

  &__title {
    @include h1;
    padding: 20px 0 20px 0;
  }

  &__container {
    display: flex;
    justify-content: space-around;
  }

  &__title-group {
    padding-bottom: 5px;
    text-align: left;
    margin-top: 10px;
  }

  &__title-img-group {
    font-size: $baseFontsSize;
    font-weight: normal;
    padding-bottom: 15px;
    width: 340px;
    text-align: left;
  }

  &__checkbox-group {
    display: flex;
    flex-wrap: wrap;
    max-width: 300px;

    div {
      width: 140px;
      margin-bottom: 15px;
      text-align: start;
    }
  }

  button#questionAuto {
    background: transparent;
    border: none;
  }

  &__button {
    width: 250px;
    height: 48px;
    margin: 30px 0 0 0;
    font-size: 18px;
  }

  &__img {
    position: relative;
    z-index: 2;
  }

  &__img-group {
    display: flex;
    flex-wrap: wrap;
    width: 340px;

    div {
      width: 50px;
      height: 50px;
      margin: 0 2px 2px 0;
      background: $mainTestColor;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      z-index: 2;
    }
  }

  &__section {
    height: 86vh;
  }

  .active {
    display: none;
  }
}

.test-finish {
  &__title {
    @include h1;
    padding: 100px 0 40px 0;
  }

  &__exit {
    color: $elementsColor;
    padding-bottom: 200px;

    &:hover {
      cursor: pointer;
    }
  }
}

.test-sound {
  min-height: calc(90vh - 50px);

  &__title {
    padding: 100px 0 20px 0;
  }

  &__audio-svg {
    padding: 25px 0 0 0;

    &:hover {
      cursor: pointer;
    }
  }

  &__gif {
    margin: 25px 0 0 0;
    width: 100px;
    height: 100px;
    border-radius: 100px;
    position: relative;
  }
}

.audio {
  display: none;
}
</style>
