<template>
  <div class="header">
    <Logo />
    <template v-if="!loginFormIsHidden">
      <div class="locale-changer">
        <select
          v-model="$i18n.locale"
          class="my-select"
        >
          <option
            v-for="(lang, i) in langs"
            :key="`Lang${i}`"
            :value="lang"
          >
            {{ lang }}
          </option>
        </select>
      </div>
    </template>
    <div class="header__block">
      <template v-if="!questionsIsHidden">
        <div
          v-for="item in testTitle"
          :key="item.id"
        >
          <div
            class="header__question"
            v-if="item.series != null"
          >
            <!-- <div>
              {{ $t('header.series') }}
              <span>
                {{ item.series }}
              </span>
              <span> {{ $t('header.with3') }}</span>
            </div> -->
            <div>
              {{ $t('header.question') }}
              <span>
                <!-- {{ item.order }} -->
                {{ questionNumber }}
              </span>
              <span>{{ $t('header.with20') }} {{ questionsPerSeries * 3 }}</span>
            </div>
          </div>
        </div>
      </template>
      <template v-if="!timerIsHidden">
        <div class="timer">
          0
          <span class="timer__colon">:</span>
          {{ sCount }}
        </div>
      </template>
    </div>
    <div class="header__block">
      <template v-if="!buttonIsHidden">
        <div
          class="header__button"
          @click="handleBillIsOpen"
        >
          <ButtonMain> <!--Завершить досрочно--> {{ $t('header.header__button-EXIT') }}</ButtonMain>
        </div>
      </template>
      <template v-if="!nameIsHidden">
        <div
          v-for="item in name"
          :key="item.id"
        >
          <span class="header__name">{{ item.name }}</span>
          <span>{{ item.surname }}</span>
        </div>
      </template>
    </div>
    <template v-if="billIsOpen">
      <ModalContainer :on-close="() => (billIsOpen = false)">
        <h3 class="modal__title"><!--Завершить досрочно-->{{ $t('header.modal__title-EXIT') }}</h3>
        <p class="modal__text"><!--Ваши данные не будут засчитаны. Вы уверены?-->{{ $t('header.modal__text-EXIT') }}</p>
        <div class="modal__button-container">
          <div
            class="modal__button"
            @click="handleBillIsClose()"
          >
            <ButtonMain class="modal__button-button"> <!--Нет-->{{ $t('header.modal__button-button-NO') }} </ButtonMain>
          </div>
          <div
            class="modal__button"
            @click="onRenouncement()"
          >
            <ButtonMain class="modal__button-button"> <!--Да-->{{ $t('header.modal__button-button-YES') }} </ButtonMain>
          </div>
        </div>
      </ModalContainer>
    </template>
  </div>
</template>

<script>
import Logo from "../assets/images/logo.svg";
import ButtonMain from "../atoms/ButtonMain.vue";
import ModalContainer from "../molecules/ModalContainer";

export default {
  name: "Header",
  components: {
    Logo,
    ButtonMain,
    ModalContainer
  },
  data() {
    return {
      billIsOpen: false,
      langs: ['ru', 'en', 'uk', 'he', 'pl', 'de'],
      test: {},
      nextSeries: {},
    };
  },
  props: {
    testTitle: {
      type: Object,
      default() {
        return {};
      },
    },
    name: {
      type: Object,
      default() {
        return {};
      },
    },
    onRenouncement: {
      type: Function,
      default() {
        return {};
      },
    },
    loginFormIsHidden: {
      type: Boolean,
      defoult: false,
    },
    timerIsHidden: {
      type: Boolean,
      defoult: false,
    },
    questionsIsHidden: {
      type: Boolean,
      defoult: false,
    },
    buttonIsHidden: {
      type: Boolean,
      defoult: false,
    },
    nameIsHidden: {
      type: Boolean,
      defoult: false,
    },
    sCount: {
      type: [String, Number],
      defoult: '',
    },
    questionsPerSeries: {
      type: [String, Number],
      defoult: '',
    },
    questionNumber: Number
  },
  methods: {
    handleBillIsOpen() {
      this.billIsOpen = true;
    },
    handleBillIsClose() {
      this.billIsOpen = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.header {
  background: $mainColor;
  height: 80px;
  padding: 0 5%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &__question {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-right: 30px;
  }

  &__name {
    padding-right: 5px;
  }

  .my-select {
    background: #a6aeb2;
    border: 1px solid #ccc;
    border-radius: 50px;
    color: #ffffff;
    font-weight: 600;
    font-size: 14px;
    padding: 10px;
    height: 36px;
    text-transform: uppercase;

    &:hover {
      cursor: pointer;
    }
  }

  .my-select:focus {
    outline: none;
    box-shadow: 0 0 5px #ccc;
  }

  .my-select option {
    background: #a6aeb2;
    border-radius: 18px;
    text-transform: uppercase;
    color: #FFFFFF;
    &:hover {
      cursor: pointer;
    }
  }

  &__block {
    display: flex;
    align-items: center;
  }

  .locale-changer + .header__block {
    display: none;
  }

  .locale-changer + .header__block + .header__block {
    display: none;
  }

  &__button {
    width: 200px;
    height: 36px;
    margin-right: 40px;
  }
}
.modal {
  &__title {
    font-size: 24px;
    padding: 70px 0 20px 0;
  }

  &__text {
    font-size: 18px;
    padding-bottom: 35px;
  }

  &__button-container {
    display: flex;
    justify-content: space-around;
    height: 48px;
  }

  &__button {
    font-size: 18px;
    width: 150px;
  }

  &__button-button {
    font-size: 18px;
  }
}
.timer {
  font-size: 36px;
  padding-left: 30px;
  width: 95px;

  &__colon {
    position: relative;
    bottom: 3px;
  }
}
</style>